<template>
  <b-container class="px-0 fixed-bottom" v-if="showPerf" fluid>
    <b-row>
      <b-col cols="12" class="text-right">
        <b-badge :variant="variant" class="border border-dark">
          {{ txtLatency }}
          <strong>{{ time }}</strong>
          <span v-if="bytes">
            |
            <i class="fas fa-download"></i>
            <strong>{{ bytes }}</strong>
          </span>
        </b-badge>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      scrollY: 0,
      txtLatency: "Latence:",
      variant: "warning",
    };
  },
  mounted() {
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll);
    window.dispatchEvent(new Event("scroll"));
  },
  computed: {
    ...mapState(["axios"]),
    showPerf() {
      if (this.$route.path === "/") return this.scrollY > 0 ? true : false;
      else return true;
    },
    time() {
      const time = parseInt(this.axios.perm_exec_time);
      if (isNaN(time)) {
        this.variant = "danger";
        this.txtLatency = "API:";
        return "hors-ligne";
      } else {
        this.variant = "warning";
        this.txtLatency = "Latence:";

        return time + "ms";
      }
    },
    bytes() {
      const time = parseInt(this.axios.perm_exec_time);
      if (!isNaN(time)) {
        return parseInt(this.axios.contentLength);
      } else return false;
    },
  },
  methods: {
    handleScroll() {
      this.scrollY = parseInt(
        (window.scrollY /
          (document.documentElement.scrollHeight -
            document.documentElement.clientHeight)) *
          100
      );
    },
  },
};
</script>
